@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");


.nav-bar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999999;
    font-family: "Roboto", sans-serif;
    font-weight: 1000;
  }
  
  /* CSS for the menu button */
  .menu-button {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    z-index: 999;
    height: 50px;
    background-color: #1ffe0100;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px;
    padding-left: 18px;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: margin 1s, height 1s, border-radius 1s, box-shadow 1s, background-color 1s, width 1s;
    display: flex;
    align-items: center;
    color:#1ffe01;
    justify-content: flex-start;
  }
  .icons{
    right: 20px;
    z-index: 9999;
    padding: 10px;
  }


  .menu-button.grow {
    width: 220px;
    height: 143px;
    z-index: 999;
    background-color: #1ffe01b9;
    color:#000000;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  .menu-button.shrink {
    color:#1ffe01;
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    z-index: 999;
    height: 50px;
    background-color: #1ffe0100;
    border-radius: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  /* CSS for the menu button when it's hovered */
  .menu-button:hover {
    background-color: #1ffe01;
  }
  
  /* CSS for the menu container */
  .menu-container {
    position: fixed;
    top: 70px;
    left: 70px;
    z-index: 9999;
    background-color: #1ffe0100;
    overflow: hidden;
    width: 150px;
    height:0;
    transition: height 2s ease-in-out;
    -webkit-animation: fadein1 2s linear forwards; /* Add 2s delay */
    animation: fadein1 2s linear forwards; /* Add 2s delay */
  }
  
  /* CSS for the menu container when it's open */
  .menu-container.open {
    height: 200px;
  }
  
  /* CSS for the menu item */
  .menu-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  /* CSS for the menu item when it's hovered */
  .menu-item:hover {
    background-color: #1ffe01;
  }
  