.lloading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, #133a19c9, #050303);
    background-position: center;
    z-index: 99;
    -webkit-animation: fadein 1s linear forwards;
    animation: fadein 1s linear forwards;
    backdrop-filter: blur(5px);
}
  
.lloading-spinner {
    border: 10px solid rgba(0, 0, 0, 0.1);
    border-top-color: #1DFE01;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1s ease-in-out infinite;
    background-color: #3d997000;
}



.lloading-h2 {
    color: #1DFE01;
    background-color: #3d997000;
}
  
@keyframes spin {
to {
      transform: rotate(360deg);
}
}
  