@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");



.login-popup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, #133a19c9, #050303);
    background-position: center;
    z-index: 99;
    -webkit-animation: fadein 1s linear forwards;
    animation: fadein 1s linear forwards;
    backdrop-filter: blur(5px);
  }
  .login-box{
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    background-color: #110d0d;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    text-align: center;
  }
  
  .login-popup p {
    margin-bottom: 20px;
    color: #ffffff8f;
    font-size: 12px;
  }
  


  .goog-login{
    margin-right: 10px;
    background-color: #d1dbd100;
    font-weight: 600;
    border: 1px solid #1DFE01;
    color: rgb(226, 219, 219);
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 12px;
    cursor: pointer;
    margin-bottom: 10px;
    width: 100%;
  }
  .goog-login:hover {
    color: #1DFE01;
  }

  .goog-close{
    background-color: #d1dbd100;
    font-weight: 600;
    font-size: 12px;
    border: none;
    color: #ffffff8f;
    padding: 10px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 12px;
    cursor: pointer;
    width: 100%;
  }
  .goog-close:hover {
    color: #1DFE01;
  }