@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

.interface-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  background-color: #110d0d;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  width: 100%;
}

.home-image-logo {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 50%;
  -webkit-animation: fadein 1s linear forwards;
  animation: fadein 1s linear forwards;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  font-family: "Roboto", sans-serif;
  margin-top: 0px;
  max-width: 80%;
  width: 100%;
  background-color: #110d0d;
  padding: 50px;
  height: 95vh;
  border-radius: 8px;
  position: relative;
}

.form-container::before,
.form-container::after,
.form-container .bottom-left::before,
.form-container .bottom-right::after {
  content: "";
  position: absolute;
  pointer-events: none;
  border-style: solid;
  border-color: #1DFE01;
}

.form-container::before {
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-width: 18px 0 0 18px;
  border-radius: 30px 0 0 0;
  opacity: 1;
}

.form-container::after {
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-width: 18px 18px 0 0;
  border-radius: 0 30px 0 0;
  opacity: 1;
}

.form-container .bottom-left::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  border-width: 0 0 18px 18px;
  border-radius: 0px 30px 0px 30px;
  opacity: 1;
}

.form-container .bottom-right::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-width: 0 18px 18px 0;
  border-radius: 0 0 30px 0px;
  opacity: 1;
}

.form-group {
  margin-bottom: 20px;
  -webkit-animation: fadein 2s linear forwards;
  animation: fadein 2s linear forwards;
  text-align: center;
  width: 80%;
}

.noise{
  padding-bottom: 40px;

}

.adv-button {
  background-color: #110d0d;
  border: 0px;
  color: #424242;
  margin-top: 30px;
  margin-bottom: -30px;
}




.file-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  color: #1dfe01;
  font-weight: 500;
  font-size: 20px;
  padding: 20px;
  border: 5px dashed #2b262c;
  border-radius: 20px;
  background-color: #f8f9fa00;
  cursor: pointer;
  text-align: center;
  transition: border 0.3s ease, color 0.3s ease; /* Add this line */
}

.file-input-container:hover {
  border: 5px dashed #1dfe01;
  color: #1dfe01;
}
.file-name:hover {
  color: #1dfe01;
  transition: color 0.3s ease, color 0.3s ease; /* Add this line */
}
.file-name {
  color: #333;
}

.form-group label {
  text-align: center;
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.form-group input,
.form-group select {
  text-align: center;
  width: 100%;
  padding: 12px;
  border-radius: 15px;
  border: 1px solid #ccc;
  font-size: 14px;
  background-color: #110d0d;
}

.form-group input[type="checkbox"] {
  width: auto;
}

.form-group-t{
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
}

.submit-button {
  padding: 12px 24px;
  background-image: linear-gradient(to right, #1dfe01, #00c056);

  color: #fff;
  font-size: 16px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 80%;
  font-weight: 500;
  -webkit-animation: fadein 1s linear forwards;
  animation: fadein 1s linear forwards;
}

.submit-button:hover {
  background-color: #0069d9;
}

.error-message {
  color: #d9534f;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

audio {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.form-group input,
.form-group select {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #5c5c5c;
  border-radius: 15px;
  font-size: 16px;
  color: #6b6b6b;
  background-color: #ffffff00;
  outline: none;
  transition: all 0.3s;
  text-align: center; /* Add this line to center the text */
}

.form-group input:focus,
.form-group select:focus {
  border-color: #1dfe01;
  color: #1dfe01;
  box-shadow: 0 0 10px rgba(30, 255, 0, 0.5);
}

.form-group select {
  border: 1px solid #15b600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  color: #6b6b6b;
  background-position: right 15px center;
  background-color: #ffffff00;
}


.options {
  margin: auto;
  text-align: center;
}
.record-button {
  padding: 12px 24px;
  background-image: linear-gradient(to right, #110d0d, #110d0d);

  color: #bd0707;
  font-size: 16px;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  font-weight: 500;
  -webkit-animation: fadein 1s linear forwards;
  animation: fadein 1s linear forwards;
}

@media screen and (max-width: 767px) {
  .home-image-logo {
    margin-bottom: 40px;
    width: 100%;
  }

  .form-container {
    
    font-family: "Roboto", sans-serif;
    max-width: 390px;
    width: 94%;
    background-color: #110d0d;
    padding: 10px;
    border-radius: 8px;
    position: relative;
      display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .form-group {
    margin-bottom: 20px;
    width: 100%;
  }

  .submit-button {
    font-family: "Roboto", sans-serif;
    padding: 12px 24px;
    color: #fff;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
  .record-button {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
  }
}



.audio-wrapper {
  width: 80%;
  height: 60px;
  background-color: #110d0d;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 16px 0;
}


audio::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  color: #110d0d;
  transition: all .1s;
  background-color: #a94672;
}

audio::-webkit-slider-thumb:hover {
  background-color: #943f65;
}

audio:hover::-webkit-slider-thumb {
  opacity: 1;
}


.advanced-settings-overlay {
  position: absolute;
  background-color: #110d0dec;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  -webkit-animation: fadein 0.2s linear forwards;
  animation: fadein 0.2s linear forwards;
  backdrop-filter: blur(5px);

}

.adv-button2 {
  background-color: #0d110d;
  border: 0px;
  color: rgba(255, 255, 255, 0.486);
  background-color: #197c0000;
  font-weight: 600;
}

.form-group2 {
  margin-bottom: 20px;
  
  color: rgba(255, 255, 255, 0.541);
  width: 80%;
}

.form-group2 input,
.form-group2 select {
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #5c5c5c;
  border-color: #1dfe01;
  border-radius: 15px;
  font-size: 16px;
  color: #1dfe01;
  background-color: #ffffff00;
  outline: none;
  transition: all 0.3s;
  text-align: center; /* Add this line to center the text */
}

.form-group2 input:focus,
.form-group2 select:focus {
  border-color: #1dfe01;
  width: 100%;
  color: #1dfe01;
  box-shadow: 0 0 10px rgba(39, 255, 0, 0.);
}

.form-group2 select {
  border: 1px solid #15b600;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  color: #6b6b6b;
  background-position: right 15px center;
  background-color: #ffffff00;
  padding-right: 40px;
}



.or-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -16px;
  margin-bottom: 6px;
  color: #424242;
}

.audio-overlay {

  position: absolute;
  background-color: #110d0dec;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  -webkit-animation: fadein 0.2s linear forwards;
  animation: fadein 0.2s linear forwards;
  backdrop-filter: blur(5px);

}
.audio-overlay .audio-wrapper {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

audio {
  width: 100%;
  color: #110d0d;
  border-radius: 2px;
}


.file-input-container-recording {
  border: 2px solid red;
  /* Add any other styles you want for the recording state */
}

.recording-text {
  color: red;
}

.red-text {
  color: red;
}

.download-button{
  background-color: #0d110d;
  border: 1px solid #1dfe01;
  margin-right: 20px;
  margin-top: 20px;
  border-radius: 14px;
  padding-left: 10px;
  padding-right: 10px;
  color: #1dfe01;
  background-color: #197c0000;
  font-weight: 600;
}


