.login-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 100vh;
    width: 100%;
    overflow: hidden;
    background-color: #110d0d00;
    -webkit-animation: fadein1 3.4s linear forwards; /* Add 2s delay */
    animation: fadein1 3.4s linear forwards; /* Add 2s delay */

}

.l-image-logo {
    margin:auto;
    width: 100%;
    background-color: #110d0d00;
  }

.l-form-container {
    font-family: "Roboto", sans-serif;
    width: 220px;
    height: 220px;
    background-color: #110d0d00;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    transition: margin 1s, height 1s, border-radius 1s, box-shadow 1s, background-color 1s, width 1s;
}

.l-form-container:hover {
    font-family: "Roboto", sans-serif;
    width: 270px;
    height: 270px;
    background-color: #110d0d00;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    transition: height 0.5s, 0.5s;
}


.l-form-container.grow {
    margin-top: 0px;
    background-color: #110d0d;
    padding: 50px;
    height: 95vh;
    border-radius: 8px;
    width: 80%;
}
  
  .l-form-container::before,
  .l-form-container::after,
  .l-form-container .bottom-left::before,
  .l-form-container .bottom-right::after {
    content: "";
    position: absolute;
    pointer-events: none;
    border-style: solid;
    border-color: #1DFE01;
  }
  
  .l-form-container::before {
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-width: 18px 0 0 18px;
    border-radius: 30px 0 0 0;
    opacity: 1;
  }
  
  .l-form-container::after {
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-width: 18px 18px 0 0;
    border-radius: 0 30px 0 0;
    opacity: 1;
  }
  
  .l-form-container .bottom-left::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50px;
    height: 50px;
    border-width: 0 0 18px 18px;
    border-radius: 0px 30px 0px 30px;
    opacity: 1;
  }
  
  .l-form-container .bottom-right::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-width: 0 18px 18px 0;
    border-radius: 0 0 30px 0px;
    opacity: 1;
  }
  


  /* Hide the default checkboxes */
.hidden {
    display: none;
}


@media screen and (max-width: 767px) {

    
    .l-form-container.grow {
        margin-top: 0px;
        background-color: #110d0d;
        padding: 50px;
        height: 95vh;
        border-radius: 8px;
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 390px;
        width: 97%;
    }
    
  }
  